import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Footer } from 'components/theme';
import { Global, CookieWrapper } from './styles';
import CookieConsent from 'react-cookie-consent';
import './fonts.css';

export const Layout = ({ children }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <Global theme={theme} />
      {children}
      <Footer />
      <CookieWrapper>
        <CookieConsent
                  location="bottom"
                  buttonText="Elfogad"
                  declineButtonText="Elutasít"
                  cookieName="gatsby-gdpr-google-analytics" 
                  enableDeclineButton >
        Honlapunk és partnereink által használt sütiket (cookie) alkalmaz annak érdekében, hogy a felhasználói élményedet emeljék. Amennyiben beleegyezik a sütik használatába válassza a Elfogadást. 
        </CookieConsent>
      </CookieWrapper>
    </>
  );
};

import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { LogoStyle } from './styles';

export const ContactImage = () => {
  const intl = useIntl();
  return (
    <StaticQuery
      query={graphql`
        query ContactImageQuery {
          file(relativePath: { eq: "praxis-kereszty-front.jpg" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <LogoStyle>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt={intl.formatMessage({ id: 'praxisAlt' })}
            title={intl.formatMessage({ id: 'praxisAlt' })}
          />
        </LogoStyle>
      )}
    />
  );
};

import footerIllustration from 'assets/illustrations/footer.svg';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 28rem 0 1rem 0;
  background-image: url(${footerIllustration});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

  @media (max-width: 960px) {
    padding: 1rem 0 0 0;
    margin-top: 1rem;
    background-image: none;
    background-color: #e5cfb8;
  }
  display: flex;
  flex-direction: row;
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 680px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;

  a {
    margin: 0 0.5rem;

    img {
      margin: 0;
    }

    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
`;

export const Details = styled.div`
  h2,
  a,
  span {
    color: #212121;
  }

  @media (max-width: 960px) {
    h2,
    a,
    span {
      color: #212121;
    }
    margin-bottom: 0;
  }

  @media (max-width: 680px) {
    margin-bottom: 1rem;
  }

  display: flex;
  flex-direction: inherit;
  width: 100%;
`;

export const Tel = styled.span`
  a {
    // color: #C4996C;
  }
`;

export const FooterLogo = styled.span`
  a {
    img {
      margin-bottom: 0;
      @media (max-width: 720px) {
        width: 70%;
      }
    }
  }
  flex: 0 0 100%;
  max-width: 400px;
`;

export const FooterText = styled.span`
  margin-top: 3em;
  @media (max-width: 1960px) {
    margin-top: 0;
  }
`;

export const DrText = styled.div`
  display: table-row;
  @media (max-width: 960px) {
    display: block;
  }
`;

export const DrTitle = styled.div`
  display: table-cell;
  @media (max-width: 960px) {
    display: block;
  }
`;

export const DrSubTitle = styled.div`
  display: table-cell;
  @media (max-width: 960px) {
    display: block;
  }
`;

import styled from 'styled-components';

export const LogoStyle = styled.a`
  .gatsby-image-wrapper {
    width: 350px;
    @media (max-width: 960px) {
      margin-bottom: 0;
      width: 60vw;
    }
  }
`;

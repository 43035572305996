import styled from 'styled-components';

export const LogoStyle = styled.a`

  .gatsby-image-wrapper {
    width: 40vw;
    margin-bottom: 0;
    max-width: 850px;
    @media (max-width: 960px) {
      width: 100vw;
      max-width: 100%;
    }
  }
`;

import React from 'react';
import { LogoStyle } from './styles';
import { StaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"

const Logo = () => {
  const intl = useIntl();
  return (
    <StaticQuery
      query={graphql`
        query CompanyLogoQuery {
          file(relativePath: { eq: "dental_profi_logo.png" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          } 
        }
      `}
      render={data => (
    <LogoStyle>
            <Img
                  fluid={data.file.childImageSharp.fluid}
                  alt={intl.formatMessage({ id: "companyAlt" })}
                  title={intl.formatMessage({ id: "companyAlt" })} 
                />
    </LogoStyle>
      )}
      />
    )
  };

export default Logo;

import React from 'react';
import { Container } from 'components/common';
import { Wrapper, Flex, Details, Tel, FooterLogo, FooterText, DrText, DrTitle, DrSubTitle  } from './styles';
import Company from '../Header/Company';
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";

export const Footer = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <FooterLogo>
          <Company />
        </FooterLogo>
        <FooterText>
          <span>
            © <FormattedMessage id="rights" /> {new Date().getFullYear()}
            <DrText>
              <DrTitle><FormattedMessage id="intro.dr.title" />,&nbsp;</DrTitle>
              <DrSubTitle><FormattedMessage id="intro.dr.subtitle" /></DrSubTitle>
            </DrText>
            <br/><a href="https://www.google.com/maps/place/Sopron,+V%C3%A1rker%C3%BClet+27,+9400/@47.687427,16.5902863,17z/data=!3m1!4b1!4m5!3m4!1s0x476c3ca22cfca5ed:0x677114a49b4e9451!8m2!3d47.687427!4d16.592475" title={useIntl().formatMessage({ id: "seo.title" })} target="blank">Sopron, Várkerület 27.</a>
            <br/><Tel><a href="tel:+36706111427">+36 70 6111427</a></Tel>
            <br/><a href="/impressum" title="impressum">Impressum</a>
          </span>
        </FooterText>
      </Details>
    </Flex>
  </Wrapper>
);

import React, {useContext} from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import ToggleTheme from 'components/theme/Header/ToggleTheme';
import { Wrapper } from './styles';
import Language from '../Language';
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import { AnchorLink } from "gatsby-plugin-anchor-links";


const NavbarLinks = ({ desktop }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper desktop={desktop} theme={theme}>
      <Link to="/praxis"><FormattedMessage id="menu.praxis" /></Link>
      <Link to="/dentalmikroskop"><FormattedMessage id="menu.dentalmikroskop" /></Link>
      <Link to="/krone"><FormattedMessage id="menu.krone" /></Link>
      <Link to="/implantat"><FormattedMessage id="menu.implantat" /></Link>
      <Link to="/digitezaleahnheilkunde"><FormattedMessage id="menu.digitezaleahnheilkunde" /></Link>
      
      {/* <AnchorLink to="/#about"><FormattedMessage id="menu.about" /></AnchorLink>
      <AnchorLink to="/#works"><FormattedMessage id="menu.works" /></AnchorLink>
      <AnchorLink to="/#contact"><FormattedMessage id="menu.contact" /></AnchorLink> */}
      <Language />
      {/* <ToggleTheme /> */}
    </Wrapper>
  )

};

export default NavbarLinks;

import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { LogoStyle } from './styles';

export const ContactMaps = () => {
  const intl = useIntl();
  return (
    <LogoStyle>
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            title="Dr. Kereszty Kornel, Sopron, Varkerulet 27."
            width="100%"
            height="100%"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=varkerulet%2027%20sopron&t=&z=17&ie=UTF8&iwloc=&output=embed"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
          ></iframe>
        </div>
      </div>
    </LogoStyle>
  );
};

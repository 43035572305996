import styled from 'styled-components';

export const LogoStyle = styled.a`
  .mapouter {
    position: relative;
    text-align: right;
    height: 100%;
    width: 100%;
  }

  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 100%;
    width: 100%;
    margin: 0;
  }
`;
